import 'owl.carousel';
import moment from 'moment'

jQuery(document).ready(function ($) {
  $('.newsletter').on('submit', function (e) {
    e.preventDefault();
    const DATA = $(this).serialize();

    $.ajax({
      type: 'POST',
      url: $(this).attr('action'),
      dataType: 'json',
      data: DATA,
      xhrFields: {
        withCredentials: false,
      },
      success: function (data) {
        $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
      },
      error: function (err) {
        console.log(err); 
      }
    });
  });

  $(".scroll").on('click', function (e) {
    e.preventDefault();
    var aid = $(this).attr("href");
    $('html,body').animate({ scrollTop: $(aid).offset().top }, 'slow');
  });


  // Tourdate Import
  $.ajax({
    url: 'https://rest.bandsintown.com/artists/id_7134700/events?app_id=45PRESS_kayley-green',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching events!');
    },
    success: data => {
      console.log(data)
      const events = $('#tour-dates');
      let html = '';
      let n = 0
      if (data.length) {
        for (let event of data) {
          n++
          html += '<div class="event-group">';
          html += '<div class="event-info-group">'
          html += '<div class="event-date">' + moment(event.datetime).format('MMM DD').toUpperCase() + '</div>';
          html += '<div class="event-venue">' + event.venue.name.toUpperCase() + '</div>';
          html += '<div class="event-location">' + event.venue.location.toUpperCase() + '</div>';
          html += '</div>'
          html += '<div class="event-links">';
          for (let offer of event.offers) {
            html += '<a href="' + offer.url + '" target="_blank" class="link btn">' + offer.type.toUpperCase() + '</a>';
          }
          html += '</div>';
          html += '</div>';
        }
        events.html(html);
      } else {
        events.html('<span class="no-events">Check back soon for new shows!</span>');
      }
      if (n < 9) {
        $("#toggle-dates").hide();
      }
    }
  });



  // CAROUSEL

  function createMusicCarousel() {
    const musicCarousel = $("#music-carousel")
    const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fa-solid fa-chevron-left"></i></div>'
    const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fa-solid fa-chevron-right"></i></div>'

    musicCarousel.owlCarousel({
      loop: true, 
      nav: true,
      dots: false,
      items: 1,
      startPosition: 0,
      autoplayTimeout: 3000,
      autoplaySpeed: 500,
      margin: 55,
      center: true,
      responsive: {
        0: {
          items: 1,
        },
        700: {
          items: 3,
        }
      },
      navText: [
        leftArrow,
        rightArrow
      ]
    })
  }
  createMusicCarousel()

});